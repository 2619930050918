@media print {
    .break-page {
        page-break-after: always;
    }

    @page {
        size: portrait;
    }

}


.id-card-portrait {
    height: 6.732in; /* Sum of front and back side width */
    width: 3.366in; /* Height of the ID card */
}
